body {
  font-family: Avenir !important;
}

.main-content {
  position: relative;
  height: 250px;
  width: 100%;
}

.main-content img {
  object-fit: cover;
  width: 100%;
  height: 250px;
  z-index: -2;
}

.pt-bg-cover {
  position: absolute;
  width: 100%;
  height: 250px;
  background-color: #1C2332;
  opacity: 0.3;
  z-index: -1;
}

.content-banner {
  position: absolute;
}

.left-top-triangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 125px;
  clip-path: polygon(0% 0, 100% 0%, 0% 100%);
  background: linear-gradient(180deg, #1363ED, white);
  opacity: 0.3;
  z-index: 5;
}

.right-triangle {
  position: absolute;
  right: 0;
  top: 0;
  height: 500px;
  width: 200px;
  clip-path: polygon(100% 100%, 100% 0%, 0% 100%);
  background: linear-gradient(#1363ED, white);
  opacity: 0.3;
  z-index: 5;
}

.banner-info {
  margin: auto auto auto 25px;
}

.main-info {
  font-size: 30px;
  font-weight: 600;
  color: white;
}

.sub-info {
  font-size: 15px;
  color: white;
  margin-top: 15px;
}

.second-content {
  background-color: #1363ED;
}

.second-text {
  color: white !important;
}

.principles-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 35px;
}

.principles-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.principles-detail {
  font-size: 13px;
  font-weight: 300px;
  opacity: 0.7;
}

.pt-section {
  background-color: #F1F4FA;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: inherit;
}

.pt-section-first {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  padding-top: 0;
}

.fake-bg {
  background-color: #1363ED;
  width: 100%;
  position: absolute;
  height: 30px;
}

.section-like-container {
  padding-left: 24px;
  padding-right: 24px;
}

.pt-container {
  background-color: #FFF;
  padding: 15px;
  border-radius: 4px;
}

.pt-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 15px;
}

.pt-title img {
  height: 16px;
  margin-right: 10px;
}

.pt-subtitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 18px;
}

.pt-text-content {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 16px;
}

.pt-text-content-last {
  position: relative;
}

.pt-text-content-last:after {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to top, rgba(255,255,255, 1) 0, rgba(255,255,255, 0) 40% );
}

.continue-arrow {
  width: 10px;
  height: 10px;
  clip-path: polygon(0% 0, 100% 0%, 50% 100%);
  background: #1363ED;
  display: inline-block;
  margin-right: 10px;
}

.continue-reading {
  color: #1267FB;
  font-weight: 700;
  font-size: 14px;
  border: none;
  outline: none;
}

.continue-reading:hover {
  cursor: pointer;
}

@media (min-width:600px) {
  .main-content {
    height: 430px;
  }

  .main-content img {
    height: 430px;
  }

  .pt-bg-cover {
    height: 430px;
  }

  .left-top-triangle {
    width: 160px;
    height: 350px;
  }

  .right-triangle {
    height: 900px;
    width: 360px;
  }

  .banner-info {
    margin: auto auto auto 50px;
  }

  .main-info {
    font-size: 48px;
  }

  .sub-info {
    font-size: 24px;
  }

  .principles-title {
    font-size: 40px;
  }

  .principles-subtitle {
    font-size: 20px;
  }

  .principles-detail {
    font-size: 15px;
  }

  .fake-bg {
    height: 30px;
  }

  .pt-title {
    font-size: 30px;
  }

  .pt-title img {
    height: 20px;
  }

  .pt-subtitle {
    font-size: 20px;
  }

  .pt-text-content {
    font-size: 18px;
  }

  .continue-arrow {
    width: 11px;
    height: 11px;
  }

  .continue-reading {
    font-size: 16px;
  }
}

@media (min-width:801px) {
  .main-content {
    height: 650px;
  }

  .main-content img {
    height: 650px;
  }

  .pt-bg-cover {
    height: 650px;
  }

  .left-top-triangle {
    width: 225px;
    height: 475px;
  }

  .right-triangle {
    height: 1100px;
    width: 450px;
  }

  .banner-info {
    margin: auto auto auto 70px;
  }

  .main-info {
    font-size: 60px;
  }

  .sub-info {
    font-size: 30px;
  }

  .principles-title {
    font-size: 45px;
  }

  .principles-subtitle {
    font-size: 22px;
  }

  .principles-detail {
    font-size: 16px;
  }

  .pt-title {
    font-size: 32px;
  }

  .pt-title img {
    height: 21px;
  }

  .pt-subtitle {
    font-size: 20px;
  }

  .pt-text-content {
    font-size: 18px;
  }

  .continue-arrow {
    width: 11px;
    height: 11px;
  }

  .continue-reading {
    font-size: 16px;
  }
}

@media (min-width:1025px) {
  .main-content {
    height: 800px;
  }

  .main-content img {
    height: 800px;
  }

  .pt-bg-cover {
    height: 800px;
  }

  .left-top-triangle {
    width: 275px;
    height: 525px;
  }

  .right-triangle {
    height: 1200px;
    width: 550px;
  }

  .banner-info {
    margin: auto auto auto 100px;
  }

  .main-info {
    font-size: 80px;
  }

  .sub-info {
    font-size: 40px;
  }

  .principles-title {
    font-size: 45px;
  }

  .principles-subtitle {
    font-size: 22px;
  }

  .principles-detail {
    font-size: 16px;
  }

  .pt-title {
    font-size: 32px;
  }

  .pt-title img {
    height: 21px;
  }

  .pt-subtitle {
    font-size: 20px;
  }

  .pt-text-content {
    font-size: 18px;
  }

  .continue-arrow {
    width: 11px;
    height: 11px;
  }

  .continue-reading {
    font-size: 16px;
  }
}

@media (min-width:1295px) {
  .main-content {
    height: 900px;
  }

  .main-content img {
    height: 900px;
  }

  .pt-bg-cover {
    height: 900px;
  }

  .left-top-triangle {
    width: 325px;
    height: 625px;
  }

  .right-triangle {
    height: 1300px;
    width: 650px;
  }

  .banner-info {
    margin: auto auto auto 200px;
  }

  .main-info {
    font-size: 100px;
  }

  .sub-info {
    font-size: 50px;
  }

  .principles-title {
    font-size: 45px;
  }

  .principles-subtitle {
    font-size: 22px;
  }

  .principles-detail {
    font-size: 16px;
  }

  .pt-title {
    font-size: 32px;
  }

  .pt-title img {
    height: 21px;
  }

  .pt-subtitle {
    font-size: 20px;
  }

  .pt-text-content {
    font-size: 18px;
  }

  .continue-arrow {
    width: 11px;
    height: 11px;
  }

  .continue-reading {
    font-size: 18px;
  }
}
