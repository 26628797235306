.ds-outer {
  background-color: #1A1C40;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1920px;
}

.ds-bg-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.ds-bg-container .circle {
  border-radius: 100000px;
  position: absolute;
}

.ds-bg-container .circle.small {
  width: 766px;
  height: 766px;
  background: linear-gradient(#016350, #1A1C40 85%);
  left: -258px;
  top: -300px;
}

.ds-bg-container .circle.big {
  width: 1314px;
  height: 1314px;
  background: linear-gradient(#653DA8, #1A1C40 50%);
  right: -200px;
  bottom: -650px;
}

.ds-infos {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #64E887;
  padding: 0 5em;
  width: 100%;
  justify-content: space-evenly;
}

.ds-infos .contents {
  width: 456px;
}

.ds-infos .ds-title {
  font-size: 64px;
  font-weight: 700;
  line-height: 1.1;
  padding: 0 0 3rem;
}

.ds-infos .ds-description {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
}

.ds-infos .image-container img {
  width: 140px;
}

@media (max-width: 1100px) {
  .ds-bg-container {
    width: 1200px;
  }

  .ds-infos {
    flex-direction: column-reverse;
    width: auto;
    height: 550px;
    text-align: center;
  }

  .ds-infos .contents {
    width: 300px;
  }

  .ds-infos .ds-title {
    font-size: 38px;
  }

  .ds-infos .ds-description {
    font-size: 18px;
  }
}

@media (max-width: 769px) {
  /* .ds-bg-container {
    height: 700px;
    width: 800px;
  } */
}

@media (max-width: 600px) {
  /* .ds-bg-container {
    width: 600px;
  } */

  .ds-bg-container .circle.big {
    right: -600px;
    bottom: -650px;
  }
}
