.footer-section {
  background: linear-gradient(180deg, #1B2231, #212C45);
  color: white !important;
  padding-top: 10px;
  padding-bottom: 35px;
}

.footer-level-top {
  margin-bottom: 0 !important;
}

.footer-level-item-logo {
  position: relative;
  bottom: -15px;
}

.footer-level-top .footer-level-item-logo img {
  width: 66px;
}

.footer-copyright {
  font-size: 20px;
  margin-left: 1em;
}

.social-link-icon {
  margin: 0 2em 0 0;
}

.social-link-icon:hover {
  opacity: 0.7;
}

.footer-inside-link {
  font-size: 14px;
  color: white !important;
}

.footer-inside-link:hover {
  opacity: 0.7;
}

.horizontal-divider {
  display: none;
}

.footer-level-item {
  justify-content: left !important;
}

.logo-img {
  border-radius: 8px;
}

@media (min-width: 769px) {
  .horizontal-divider {
    display: block;
    border-left: solid 1px white;
    height: 1em;
    margin-right: 0.75em;
  }
}
