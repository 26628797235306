.navbar {
  position: fixed;
  width: 100%;
  justify-content: space-around;
  display: flex;
}

.navbar-menu {
  flex-grow: 0 !important;
}

.navbar-menu.visible {
  display: block;
}

.navigator-site-title {
  font-size: 18px;
  font-weight: 400;
  display: flex;
}

.navigator-site-title .suffix-version {
  display: block;
  font-size: 17px;
  font-weight: 300;
  opacity: 0.7;
}

.top-dl-buttons {
  padding: 0.5rem 0.75rem;
  /* width: 290px; */
  justify-content: space-around;
}

.top-dl-buttons .google {
  display: none;
}

.top-dl-buttons.visible {
  display: flex;
}

.top-dl-buttons.hidden {
  display: none;
}

.navbar-burger {
  outline: none;
  border: none;
  background-color: #fff;
  color: white;
}


/*
/ dark theme styles
 */

.navbar.dark {
  background-color: #000;
}

.navbar.dark.transparent {
  background-color: rgb(0, 0, 0, 0.3);
}

.navbar.dark.solid {
  background-color: rgb(0, 0, 0, 1);
}

.navbar.dark.to-transparent {
  animation-name: toTransparentAnimation;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

.navbar.dark.to-solid {
  animation-name: toSolidAnimation;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

@keyframes toTransparentAnimation {
  0% {
    background-color: rgb(0, 0, 0, 1);
  }
  100% {
    background-color: rgb(0, 0, 0, 0.3);
  }
}

@keyframes toSolidAnimation {
  0% {
    background-color: rgb(0, 0, 0, 0.3);
  }
  100% {
    background-color: rgb(0, 0, 0, 1);
  }
}

.navigator-site-title.dark {
  color: white;
}

.navbar-menu.dark {
  background-color: #000;
}

.navbar-item.dark {
  color: white;
}

.nav-logo {
  border-radius: 4px;
}

.navbar-burger.dark {
  background-color: #000;
}

.navbar-burger:active {
  background-color: white;
}

.navigator-site-title .version-container {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-version-spinner {
  animation-name: spinnerAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@media (max-width: 769px) {
  .navigator-site-title .version-container {
    display: none;
  }
}
