.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 375px;
  min-height: 270px;
  padding: 1em;
  border: none;
  box-shadow: none;
}

.card .picture img {
  width: 132px;
}

.card .title {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.card .description {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: #333;
}

@media (max-width: 769px) {
  .card {
    width: 325px;
  }
}

@media (max-width: 600px) {
  .card {
    width: 300px;
  }
}
