.ff-onvisible-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ff-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 1920px;
  background-color: #000;
}

.ff-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  height: 500px;
  justify-content: space-around;
}

.real-logo-container {
  width: 96px;
  height: 96px !important;
  position: relative;
  border-radius: 24px;
}

.transitionfix {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

.real-logo-container .cutter {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 24px;
}

.real-logo-container .cutter .in-cutter {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.real-logo-container .ff-logo-eye {
  position: absolute;
  width: 62px;
  z-index: 20;
  opacity: 0.6;
  border-radius: 24px;
  background-blend-mode: color-dodge;
  top: 36px;
  left: 16px;
}

.real-logo-container .ff-logo-bg {
  width: 130px;
  height: auto;
  border-radius: 24px;
  animation-name: fancyLogoSpin;
  animation-duration: 22s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ff-new-logo {
  margin-top: 15px;
  width: 100px;
}

.ff-container .ff-text-main {
  font-size: 64px;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  margin: 0.5em auto;
  background: -webkit-linear-gradient(0, #5C0C62 8%, #F0125F 25%, #691759 43%, #691759 57%, #F0125F 70%, #5C0C62 92%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: shine 10s linear infinite;
}

.download-buttons .google {
  display: none;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.download-buttons .dl-button {
  margin-left: 0.75em;
  margin-right: 0.75em;
}

@keyframes fancyLogoSpin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .ff-outer {
    height: 100vh;
    width: 100%;
  }

  .ff-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    height: 500px;
    justify-content: center;
  }

  .ff-container .ff-text-main {
    font-size: 38px;
  }
}

@media (max-width: 320px) {
  .ff-container .ff-text-main {
    font-size: 32px;
  }

  .ff-container {
    justify-content: center;
  }
}
