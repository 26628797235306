.page-container {
  position: relative;
}

.page-container .ul-banner {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/ul_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.page-container .download-part {
  text-align: center;
  padding: 0 2em 1em;
}

.page-container .bottom-container {
  position: fixed;
  background-color: white;
  bottom: 0;
  width: 100%;
  border-radius: 25px 25px 0 0;
}

.bottom-container .center-box {
  position: absolute;
  left: 50%;
  width: 80px;
  height: 80px;
  top: -36px;
}

.ul-title {
  font-size: 24px;
  font-weight: 600;
}

.ul-text-content {
  font-size: 16px;
  font-weight: 400;
  color: #5C6880;
}

.stub-box {
  padding: 25px;
  position: relative;
}

.stub-box img {
  position: absolute;
  left: -50%;
  width: 80px;
  height: 80px;
}

.ul-main-text {
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  padding-top: 2em;
}

.ul-download-button {
  background-color: #000;
  color: white;
  font-weight: 600;
  border: 1px solid #A7A7A7;
  line-height: 1.2;
  border-radius: 8px;
  margin-top: 0.5em;
  padding: 1.6em 1.25em !important;
}

.ul-download-button:hover {
  opacity: 0.65;
  color: white !important;
}

.ul-download-button img.ul-download-icon {
  height: 2em;
  z-index: auto;
  width: 2em;
  margin-right: 1em;
}

.ul-download-button .small-text {
  font-size: 13px;
}

.ul-download-button .larger-text {
  font-size: 22px;
}
