body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #606c71;
}

.section {
  overflow: hidden;
}

.section.dark {
  background-color: #000;
}

.section.light {
  background-color: #fff;
}

.width-limit-container {
  max-width: 1100px;
  margin: auto;
}

.scale-container {
  overflow: hidden;
}

.slider-card {
  width: 245px;
  min-height: 425px;
  margin: auto 6px;
  box-sizing: border-box;
  border: 1px solid #fff;
  box-shadow: none !important;
}

.disappear {
  visibility: hidden;
}

.appear {
  visibility: visible;
}

@keyframes spinnerAnimation {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@media (min-width: 360px) {
  .slider-card {
    width: 260px;
    margin: auto 15px;
  }
}

@media (min-width: 600px) {
  .slider-card {
    margin: auto 40px;
  }
}

@media (min-width: 1024px) {
  .slider-card {
    margin: auto 30px;
  }
}

@media (min-width: 1295px) {
  .slider-card {
    width: 276px;
    margin: auto 30px;
  }
}

@media (min-width: 1088) {
  .width-limit-container {
    max-width: 1200px;
  }
}
