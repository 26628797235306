.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-inner-container {
  max-width: 1920px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  clip: rect(0, auto, auto, 0);
}

.images-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.images-container .phone {
  width: 350px;
}

.images-container .tablet {
  width: 590px;
  display: block;
}

.texts-container {
  width: 409px;
}

.texts-container .feature-point {
  font-size: 34px;
  font-weight: 600;
}

.texts-container .highlight {
  font-size: 48px;
  font-weight: 700;
  color: #FF5100;
}

.texts-container .description .items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.texts-container .description .items .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.25em;
}

.texts-container .description .items .item .icon {
  width: 34px;
  height: auto;
  margin-right: 2.25em;
}

.slick-vertical .slick-slide {
  border: none !important;
}

@media (max-width: 1200px) {

  .images-container.pull-to-start {
    justify-content: flex-start;
  }

  .images-container.pull-to-end {
    justify-content: flex-end;
  }

  .images-container .tablet {
    display: none;
  }
}

@media (max-width: 769px) {

  .images-container.pull-to-start {
    justify-content: center;
  }

  .images-container.pull-to-end {
    justify-content: center;
  }

  .images-container .tablet {
    display: none;
  }
}
