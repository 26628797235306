.download-button {
  background-color: #000;
  color: white;
  font-weight: 600;
  margin-top: 1em;
  border: 1px solid #A7A7A7;
  line-height: 1.2;
  border-radius: 8px;
  padding: 0.5rem 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.download-button:active {
  opacity: 0.7;
  color: white;
}

.download-button:hover {
  border: 1px solid white;
  color: white;
}

.download-button .brand-icon {
  height: 2.2rem;
  z-index: auto;
  width: 2.2rem;
  margin-right: 0.75rem;
}

.download-button .small-text {
  font-size: 0.9rem;
}

.download-button .larger-text {
  font-size: 1.6rem;
}

.download-button.mini-size {
  margin: 0;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}

.download-button.mini-size .brand-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.download-button.mini-size .small-text {
  font-size: 0.5rem;
}

.download-button.mini-size .larger-text {
  font-size: 0.8125rem;
}

@media (min-width: 769px) {
  .download-button {
    margin-top: 2rem;
  }

  .download-button .brand-icon {
    height: 2.5rem;
    z-index: auto;
    width: 2.5rem;
    margin-right: 0.75rem;
  }

  .download-button .small-text {
    font-size: 15px;
  }

  .download-button .larger-text {
    font-size: 26px;
  }
}
