.feature-section-outer {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1920px;
}

.features-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1125px;
  height: 100vh;
  padding-top: 6rem;

}

.features-section .title {
  font-size: 64px;
  font-weight: 700;
  text-align: center;
}

.features-section .feature-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1125px;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 1100px) {
  .features-section {

  }

  .features-section .feature-line {
    width: 750px;
    justify-content: flex-start;
  }

  .features-section {
    width: 750px;
  }
}

@media (max-width: 769px) {
  .features-section .feature-line {
    width: 650px;
  }

  .features-section {
    height: auto;
    width: 650px;
    padding: 6rem 0;
  }
}

@media (max-width: 600px) {
  .features-section {
    height: auto;
  }

  .features-section .title {
    font-size: 38px;
  }

  .features-section {
    width: 300px;
  }

  .features-section .feature-line {
    width: 300px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
