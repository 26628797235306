.nf-container-cus {
  text-align: center;
  height: 500px;
  background-color: white;
  padding-top: 200px;
}

.nf-site-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0.75em;
}

.nf-page-container {
  background-color: #F1F4FA;
  height: 100vh;
}

.nf-main-info {
  font-size: 32px;
  font-weight: 600;
}
