.container-cus {
  text-align: center;
  height: 500px;
  background-color: white;
  padding-top: 100px;
  margin-top: 50px;
}

.site-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0.75em;
}

.ev-page-container {
  background-color: #F1F4FA;
  height: 100vh;
}

.verify-result-text {
  font-size: 24px;
  font-weight: 500;
  margin-top: 1.5em;
}
