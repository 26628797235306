body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body.fade {
    animation-name: bodyFade;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
}

@keyframes bodyFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
