.banner-outer {
  background: linear-gradient(70deg, #AF2A95, #F9BFCD);
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-bg-container {
  width: 1920px;
  height: 100vh;
  display: flex;
}

.banner-bg-container .left-box, .banner-bg-container .right-box {
  width: 50%;
  height: 100%;
  position: relative;
}

.banner-bg-container .left-box {
  display: flex;
  flex-direction: column;
}

.banner-bg-container .left-box .left-top-box, .banner-bg-container .left-box .left-bottom-box {
  width: 100%;
  height: 50%;
  position: relative;
}

.banner-bg-container .circle {
  border-radius: 100000px;
  position: absolute;
}

.banner-bg-container .circle.small {
  width: 277px;
  height: 277px;
  background: linear-gradient(-40deg, #BF4BA1 20%, #FF1F79 85%);
  right: 300px;
  bottom: -50px;
  z-index: 2;
  animation: circleSmallBounce 18s 1;
}

.banner-bg-container .circle.middle {
  width: 918px;
  height: 918px;
  background: linear-gradient(70deg, #FF1F79 35%, #C14FA3 90%);
  top: 30px;
  right: -120px;
  z-index: 1;
  animation: circleMiddleBounce 18s 1;
}

.banner-bg-container .circle.big {
  width: 1170px;
  height: 1170px;
  background: linear-gradient(65deg, #C14EA2, #F6DD6A 60%);
  left: -150px;
  top: -200px;
  z-index: 3;
  animation: circleBigBounce 18s 1;
}

.banner-infos {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 0 5em;
}

.logo-container {
  width: 96px;
  height: 96px;
}

.banner-logo {
  width: 96px;
  height: auto;
  border-radius: 15px;
}

.banner-text-main {
  font-size: 80px;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  margin: 0.5em auto;
}

.banner-text-more {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.download-buttons .dl-button {
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.download-buttons .google {
  display: none;
}

@keyframes circleBigBounce {
  0% {
    transform: translate(2000px, -1000px);
  }
  4% {
    transform: translate(-30px, 15px);
  }
  52% {
    transform: translate(20px, -10px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes circleMiddleBounce {
  0% {
    transform: translate(-1600px, 800px);
  }
  4% {
    transform: translate(40px, -20px);
  }
  52% {
    transform: translate(-30px, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes circleSmallBounce {
  0% {
    transform: translateX(-100px);
  }
  4% {
    transform: translateX(0);
  }
  52% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 1440px) {
  .banner-bg-container .circle.big {
    top: -300px;
  }
}

@media (max-width: 1200px) {
  .banner-bg-container {
    width: 1200px;
  }

  .banner-bg-container .circle.small {
    right: 350px;
    bottom: 150px;
  }

  .banner-bg-container .circle.middle {
    top: -110px;
  }

  .banner-bg-container .circle.big {
    top: -200px;
  }
}

@media (max-width: 769px) {

  .banner-bg-container {
    width: 769px;
  }

  .banner-bg-container .circle.small {
    right: 240px;
    bottom: 150px;
  }

  .banner-bg-container .circle.middle {
    top: -150px;
  }

  .banner-bg-container .circle.big {
    top: -380px;
  }

  .banner-text-main {
    font-size: 80px;
  }

  .banner-text-more {
    font-size: 32px;
  }

  .banner-text-more.last {
    margin-bottom: 5em;
  }
}

@media (max-width: 414px) {
  .banner-outer {
    width: 100%;
  }

  .banner-bg-container {
    width: 100%;
  }

  .banner-bg-container .circle.small {
    width: 150px;
    height: 150px;
    bottom: 80px;
    right: 120px;
  }

  .banner-bg-container .circle.middle {
    width: 450px;
    height: 450px;
    top: -60px;
    right: -30px;
  }

  .banner-bg-container .circle.big {
    width: 650px;
    height: 650px;
    left: -90px;
    top: -100px;
  }

  .banner-infos {
    padding: 0 2em;
  }

  .banner-text-main {
    font-size: 36px;
  }

  .banner-text-more {
    font-size: 16px;
  }

  .banner-text-more.last {
    margin-bottom: 3em;
  }

  .download-buttons .google {
    display: none;
  }
}

@media (max-width: 320px) {
  .banner-bg-container .circle.small {
    width: 150px;
    height: 150px;
    right: 95px;
    bottom: 80px;
  }

  .banner-bg-container .circle.middle {
    width: 450px;
    height: 450px;
    right: -40px;
  }

  .banner-bg-container .circle.big {
    width: 600px;
    height: 600px;
    top: -180px;
  }

  .banner-infos {
    padding: 0 2em;
  }

  .banner-text-main {
    font-size: 36px;
  }

  .banner-text-more {
    font-size: 14px;
  }

  .banner-text-more.last {
    margin-bottom: 2em;
  }

  .download-buttons .google {
    display: none;
  }
}
