.comment-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  min-height: 350px;
  border: 1px solid #E9E9E9;
  border-radius: 6px;
  box-shadow: 0 30px 40px 0 #E9E9E9;
  padding: 0 1rem;
}

.comment-container .comment-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 350px;
  height: 350px;
  justify-content: space-around;
}

.comment-container .comment-details .head {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comment-container .comment-details .comment-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.comment-container .comment-details .comment-score {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.comment-container .comment-details .comment-detail {
  font-size: 1rem;
  font-weight: 400;
  color: #666;
  font-style: italic;
}

.comment-container .comment-details .comment-other-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  font-weight: 300;
  color: #666;
}

.comment-container .comment-details .comment-other-info .user-nickname {
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comment-container .switch-icon {
  font-size: 24px;
  font-weight: 400;
  opacity: 0.15;
}

.comment-container .rigth-arrow, .comment-container .left-arrow {
  display: block;
}

.comment-container .rigth-arrow:hover, .comment-container .left-arrow:hover {
  cursor: pointer;
}

.comment-container .rigth-arrow:active, .comment-container .left-arrow:active {
  opacity: 0.7;
}

.comment-fade-in {
  animation: commentFadeIn 0.5s ease;
}

.comment-fade-out {
  animation: commentFadeOut 0.5s ease;
}

.comment-spinner {
  animation-name: spinnerAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes commentFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes commentFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 1100px) {

  .comment-container {
    border: none;
    padding: 0;
    justify-content: center;
  }

  .comment-container .rigth-arrow, .comment-container .left-arrow {
    display: none;
  }
}

@media (max-width: 769px) {
}

@media (max-width: 414px) {

  .comment-container {
    width: 350px;
  }

  .comment-container .comment-details .comment-detail {
    width: 260px;
  }
}

@media (max-width: 320px) {

  .comment-container {
    width: 320px;
  }

  .comment-container .comment-details .comment-detail {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    width: 250px;
  }
}
