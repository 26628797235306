.uc-outer {
  background-color: #fff;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uc-container {
  width: 1920px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uc-container .uc-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5em;
  width: 100%;
  justify-content: space-evenly;
}

.uc-container .uc-description {
  font-size: 64px;
  font-weight: 700;
  color: #111;
  padding: 0 20px;
  width: 450px;
  line-height: 1.2;
}

@media (max-width: 1100px) {
  .uc-container .uc-inner-container {
    flex-direction: column;
    text-align: center;
  }

  .uc-container .uc-description {
    font-size: 48px;
    width: 350px;
    margin-bottom: 1.25em;
  }

  .uc-container {
    width: 1200px;
    /* height: auto; */
    padding: 5em 0
  }
}

@media (max-width: 769px) {
  .uc-container {
    height: auto;
  }

  .uc-container .uc-inner-container {
    flex-direction: column;
    text-align: center;
  }

  .uc-container .uc-description {
    font-size: 38px;
  }
}

@media (max-width: 414px) {
  .uc-container .uc-description {
    font-size: 38px;
    width: 320px;
    margin-bottom: 1.25em;
  }

  .uc-container {
    width: 1200px;
    height: auto;
    padding: 5em 0
  }
}
