body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body.fade {
    -webkit-animation-name: bodyFade;
            animation-name: bodyFade;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

@-webkit-keyframes bodyFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bodyFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #606c71;
}

.section {
  overflow: hidden;
}

.section.dark {
  background-color: #000;
}

.section.light {
  background-color: #fff;
}

.width-limit-container {
  max-width: 1100px;
  margin: auto;
}

.scale-container {
  overflow: hidden;
}

.slider-card {
  width: 245px;
  min-height: 425px;
  margin: auto 6px;
  box-sizing: border-box;
  border: 1px solid #fff;
  box-shadow: none !important;
}

.disappear {
  visibility: hidden;
}

.appear {
  visibility: visible;
}

@-webkit-keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@media (min-width: 360px) {
  .slider-card {
    width: 260px;
    margin: auto 15px;
  }
}

@media (min-width: 600px) {
  .slider-card {
    margin: auto 40px;
  }
}

@media (min-width: 1024px) {
  .slider-card {
    margin: auto 30px;
  }
}

@media (min-width: 1295px) {
  .slider-card {
    width: 276px;
    margin: auto 30px;
  }
}

@media (min-width: 1088) {
  .width-limit-container {
    max-width: 1200px;
  }
}

.container-cus {
  text-align: center;
  height: 500px;
  background-color: white;
  padding-top: 100px;
  margin-top: 50px;
}

.site-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0.75em;
}

.ev-page-container {
  background-color: #F1F4FA;
  height: 100vh;
}

.verify-result-text {
  font-size: 24px;
  font-weight: 500;
  margin-top: 1.5em;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-inner-container {
  max-width: 1920px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  clip: rect(0, auto, auto, 0);
}

.images-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.images-container .phone {
  width: 350px;
}

.images-container .tablet {
  width: 590px;
  display: block;
}

.texts-container {
  width: 409px;
}

.texts-container .feature-point {
  font-size: 34px;
  font-weight: 600;
}

.texts-container .highlight {
  font-size: 48px;
  font-weight: 700;
  color: #FF5100;
}

.texts-container .description .items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.texts-container .description .items .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.25em;
}

.texts-container .description .items .item .icon {
  width: 34px;
  height: auto;
  margin-right: 2.25em;
}

.slick-vertical .slick-slide {
  border: none !important;
}

@media (max-width: 1200px) {

  .images-container.pull-to-start {
    justify-content: flex-start;
  }

  .images-container.pull-to-end {
    justify-content: flex-end;
  }

  .images-container .tablet {
    display: none;
  }
}

@media (max-width: 769px) {

  .images-container.pull-to-start {
    justify-content: center;
  }

  .images-container.pull-to-end {
    justify-content: center;
  }

  .images-container .tablet {
    display: none;
  }
}

.navbar {
  position: fixed;
  width: 100%;
  justify-content: space-around;
  display: flex;
}

.navbar-menu {
  flex-grow: 0 !important;
}

.navbar-menu.visible {
  display: block;
}

.navigator-site-title {
  font-size: 18px;
  font-weight: 400;
  display: flex;
}

.navigator-site-title .suffix-version {
  display: block;
  font-size: 17px;
  font-weight: 300;
  opacity: 0.7;
}

.top-dl-buttons {
  padding: 0.5rem 0.75rem;
  /* width: 290px; */
  justify-content: space-around;
}

.top-dl-buttons .google {
  display: none;
}

.top-dl-buttons.visible {
  display: flex;
}

.top-dl-buttons.hidden {
  display: none;
}

.navbar-burger {
  outline: none;
  border: none;
  background-color: #fff;
  color: white;
}


/*
/ dark theme styles
 */

.navbar.dark {
  background-color: #000;
}

.navbar.dark.transparent {
  background-color: rgb(0, 0, 0, 0.3);
}

.navbar.dark.solid {
  background-color: rgb(0, 0, 0, 1);
}

.navbar.dark.to-transparent {
  -webkit-animation-name: toTransparentAnimation;
          animation-name: toTransparentAnimation;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.navbar.dark.to-solid {
  -webkit-animation-name: toSolidAnimation;
          animation-name: toSolidAnimation;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes toTransparentAnimation {
  0% {
    background-color: rgb(0, 0, 0, 1);
  }
  100% {
    background-color: rgb(0, 0, 0, 0.3);
  }
}

@keyframes toTransparentAnimation {
  0% {
    background-color: rgb(0, 0, 0, 1);
  }
  100% {
    background-color: rgb(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes toSolidAnimation {
  0% {
    background-color: rgb(0, 0, 0, 0.3);
  }
  100% {
    background-color: rgb(0, 0, 0, 1);
  }
}

@keyframes toSolidAnimation {
  0% {
    background-color: rgb(0, 0, 0, 0.3);
  }
  100% {
    background-color: rgb(0, 0, 0, 1);
  }
}

.navigator-site-title.dark {
  color: white;
}

.navbar-menu.dark {
  background-color: #000;
}

.navbar-item.dark {
  color: white;
}

.nav-logo {
  border-radius: 4px;
}

.navbar-burger.dark {
  background-color: #000;
}

.navbar-burger:active {
  background-color: white;
}

.navigator-site-title .version-container {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-version-spinner {
  -webkit-animation-name: spinnerAnimation;
          animation-name: spinnerAnimation;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@media (max-width: 769px) {
  .navigator-site-title .version-container {
    display: none;
  }
}

.download-button {
  background-color: #000;
  color: white;
  font-weight: 600;
  margin-top: 1em;
  border: 1px solid #A7A7A7;
  line-height: 1.2;
  border-radius: 8px;
  padding: 0.5rem 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.download-button:active {
  opacity: 0.7;
  color: white;
}

.download-button:hover {
  border: 1px solid white;
  color: white;
}

.download-button .brand-icon {
  height: 2.2rem;
  z-index: auto;
  width: 2.2rem;
  margin-right: 0.75rem;
}

.download-button .small-text {
  font-size: 0.9rem;
}

.download-button .larger-text {
  font-size: 1.6rem;
}

.download-button.mini-size {
  margin: 0;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}

.download-button.mini-size .brand-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.download-button.mini-size .small-text {
  font-size: 0.5rem;
}

.download-button.mini-size .larger-text {
  font-size: 0.8125rem;
}

@media (min-width: 769px) {
  .download-button {
    margin-top: 2rem;
  }

  .download-button .brand-icon {
    height: 2.5rem;
    z-index: auto;
    width: 2.5rem;
    margin-right: 0.75rem;
  }

  .download-button .small-text {
    font-size: 15px;
  }

  .download-button .larger-text {
    font-size: 26px;
  }
}

.banner-outer {
  background: -webkit-linear-gradient(20deg, #AF2A95, #F9BFCD);
  background: linear-gradient(70deg, #AF2A95, #F9BFCD);
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-bg-container {
  width: 1920px;
  height: 100vh;
  display: flex;
}

.banner-bg-container .left-box, .banner-bg-container .right-box {
  width: 50%;
  height: 100%;
  position: relative;
}

.banner-bg-container .left-box {
  display: flex;
  flex-direction: column;
}

.banner-bg-container .left-box .left-top-box, .banner-bg-container .left-box .left-bottom-box {
  width: 100%;
  height: 50%;
  position: relative;
}

.banner-bg-container .circle {
  border-radius: 100000px;
  position: absolute;
}

.banner-bg-container .circle.small {
  width: 277px;
  height: 277px;
  background: -webkit-linear-gradient(130deg, #BF4BA1 20%, #FF1F79 85%);
  background: linear-gradient(-40deg, #BF4BA1 20%, #FF1F79 85%);
  right: 300px;
  bottom: -50px;
  z-index: 2;
  -webkit-animation: circleSmallBounce 18s 1;
          animation: circleSmallBounce 18s 1;
}

.banner-bg-container .circle.middle {
  width: 918px;
  height: 918px;
  background: -webkit-linear-gradient(20deg, #FF1F79 35%, #C14FA3 90%);
  background: linear-gradient(70deg, #FF1F79 35%, #C14FA3 90%);
  top: 30px;
  right: -120px;
  z-index: 1;
  -webkit-animation: circleMiddleBounce 18s 1;
          animation: circleMiddleBounce 18s 1;
}

.banner-bg-container .circle.big {
  width: 1170px;
  height: 1170px;
  background: -webkit-linear-gradient(25deg, #C14EA2, #F6DD6A 60%);
  background: linear-gradient(65deg, #C14EA2, #F6DD6A 60%);
  left: -150px;
  top: -200px;
  z-index: 3;
  -webkit-animation: circleBigBounce 18s 1;
          animation: circleBigBounce 18s 1;
}

.banner-infos {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 0 5em;
}

.logo-container {
  width: 96px;
  height: 96px;
}

.banner-logo {
  width: 96px;
  height: auto;
  border-radius: 15px;
}

.banner-text-main {
  font-size: 80px;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  margin: 0.5em auto;
}

.banner-text-more {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.download-buttons .dl-button {
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.download-buttons .google {
  display: none;
}

@-webkit-keyframes circleBigBounce {
  0% {
    -webkit-transform: translate(2000px, -1000px);
            transform: translate(2000px, -1000px);
  }
  4% {
    -webkit-transform: translate(-30px, 15px);
            transform: translate(-30px, 15px);
  }
  52% {
    -webkit-transform: translate(20px, -10px);
            transform: translate(20px, -10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes circleBigBounce {
  0% {
    -webkit-transform: translate(2000px, -1000px);
            transform: translate(2000px, -1000px);
  }
  4% {
    -webkit-transform: translate(-30px, 15px);
            transform: translate(-30px, 15px);
  }
  52% {
    -webkit-transform: translate(20px, -10px);
            transform: translate(20px, -10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@-webkit-keyframes circleMiddleBounce {
  0% {
    -webkit-transform: translate(-1600px, 800px);
            transform: translate(-1600px, 800px);
  }
  4% {
    -webkit-transform: translate(40px, -20px);
            transform: translate(40px, -20px);
  }
  52% {
    -webkit-transform: translate(-30px, 15px);
            transform: translate(-30px, 15px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes circleMiddleBounce {
  0% {
    -webkit-transform: translate(-1600px, 800px);
            transform: translate(-1600px, 800px);
  }
  4% {
    -webkit-transform: translate(40px, -20px);
            transform: translate(40px, -20px);
  }
  52% {
    -webkit-transform: translate(-30px, 15px);
            transform: translate(-30px, 15px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@-webkit-keyframes circleSmallBounce {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  4% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  52% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes circleSmallBounce {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  4% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  52% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@media (max-width: 1440px) {
  .banner-bg-container .circle.big {
    top: -300px;
  }
}

@media (max-width: 1200px) {
  .banner-bg-container {
    width: 1200px;
  }

  .banner-bg-container .circle.small {
    right: 350px;
    bottom: 150px;
  }

  .banner-bg-container .circle.middle {
    top: -110px;
  }

  .banner-bg-container .circle.big {
    top: -200px;
  }
}

@media (max-width: 769px) {

  .banner-bg-container {
    width: 769px;
  }

  .banner-bg-container .circle.small {
    right: 240px;
    bottom: 150px;
  }

  .banner-bg-container .circle.middle {
    top: -150px;
  }

  .banner-bg-container .circle.big {
    top: -380px;
  }

  .banner-text-main {
    font-size: 80px;
  }

  .banner-text-more {
    font-size: 32px;
  }

  .banner-text-more.last {
    margin-bottom: 5em;
  }
}

@media (max-width: 414px) {
  .banner-outer {
    width: 100%;
  }

  .banner-bg-container {
    width: 100%;
  }

  .banner-bg-container .circle.small {
    width: 150px;
    height: 150px;
    bottom: 80px;
    right: 120px;
  }

  .banner-bg-container .circle.middle {
    width: 450px;
    height: 450px;
    top: -60px;
    right: -30px;
  }

  .banner-bg-container .circle.big {
    width: 650px;
    height: 650px;
    left: -90px;
    top: -100px;
  }

  .banner-infos {
    padding: 0 2em;
  }

  .banner-text-main {
    font-size: 36px;
  }

  .banner-text-more {
    font-size: 16px;
  }

  .banner-text-more.last {
    margin-bottom: 3em;
  }

  .download-buttons .google {
    display: none;
  }
}

@media (max-width: 320px) {
  .banner-bg-container .circle.small {
    width: 150px;
    height: 150px;
    right: 95px;
    bottom: 80px;
  }

  .banner-bg-container .circle.middle {
    width: 450px;
    height: 450px;
    right: -40px;
  }

  .banner-bg-container .circle.big {
    width: 600px;
    height: 600px;
    top: -180px;
  }

  .banner-infos {
    padding: 0 2em;
  }

  .banner-text-main {
    font-size: 36px;
  }

  .banner-text-more {
    font-size: 14px;
  }

  .banner-text-more.last {
    margin-bottom: 2em;
  }

  .download-buttons .google {
    display: none;
  }
}

.progress-dots-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 3rem;
  z-index: 11;
  height: 152px;
  justify-content: space-between;
}

.progress-dots-container .solid-circle.current {
  font-size: 12px;
}

.progress-dots-container .solid-circle.others {
  font-size: 8px;
  opacity: 0.3;
}

.progress-dots-container .solid-circle.colored {

}

.progress-dots-container .solid-circle.normal {

}

@media (max-width: 1440px) {
  .progress-dots-container {
    display: none;
  }
}

.content-outer {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container {
  display: flex;
  width: 1920px;
  height: 100vh;
  padding: 5em 0 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.content-container.direction-normal {
  flex-direction: row;
}

.content-container.direction-reverse {
  flex-direction: row-reverse;
}

.content-container .text-part, .content-container .image-part {
  width: 50%;
}

.content-container .text-part {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10em;
}



.content-container .text-part.reversed {
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .content-container .text-part {
    padding: 0 2em;
  }

  .content-container .image-part {
    padding: 2em;
  }
}

@media (max-width: 769px) {
  .content-container {
    height: auto;
  }

  .content-container.direction-normal {
    flex-direction: column;
  }

  .content-container.direction-reverse {
    flex-direction: column;
  }

  .content-container .text-part {
    justify-content: center;
  }

  .content-container {
    height: auto;
    padding: 5em 0 0;
  }

  .content-container .text-part.reversed {
    justify-content: center;
  }
}

.texts-container {
  width: 409px;
}

.texts-container .feature-point {
  font-size: 32px;
  font-weight: 500;
  color: #222;
}

.texts-container .highlight {
  font-size: 48px;
  font-weight: 700;
  color: #FF5100;
  line-height: 1.1;
  padding: 0.5em 0;
}

.texts-container .highlight.purple {
  color: #B63CE3;
}

.texts-container .highlight.yellow {
  color: #FF9F00;
}

.texts-container .highlight.cyan {
  color: #2A928A;
}

.texts-container .description .items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.texts-container .description .items .plain-text {
  font-size: 18px;
  font-weight: 700;
}

.texts-container .description .items .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.25em;
}

.texts-container .description .items .item .icon {
  width: 34px;
  height: auto;
  margin-right: 2.25em;
}

.texts-container .description .items .item .comment {
  font-size: 18px;
}

@media (max-width: 1200px) {
  .texts-container {
    width: 320px;
  }
}

@media (max-width: 320px) {
  .texts-container {
    width: 280px;
  }
}

.feature-section-outer {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1920px;
}

.features-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1125px;
  height: 100vh;
  padding-top: 6rem;

}

.features-section .title {
  font-size: 64px;
  font-weight: 700;
  text-align: center;
}

.features-section .feature-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1125px;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 1100px) {
  .features-section {

  }

  .features-section .feature-line {
    width: 750px;
    justify-content: flex-start;
  }

  .features-section {
    width: 750px;
  }
}

@media (max-width: 769px) {
  .features-section .feature-line {
    width: 650px;
  }

  .features-section {
    height: auto;
    width: 650px;
    padding: 6rem 0;
  }
}

@media (max-width: 600px) {
  .features-section {
    height: auto;
  }

  .features-section .title {
    font-size: 38px;
  }

  .features-section {
    width: 300px;
  }

  .features-section .feature-line {
    width: 300px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 375px;
  min-height: 270px;
  padding: 1em;
  border: none;
  box-shadow: none;
}

.card .picture img {
  width: 132px;
}

.card .title {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.card .description {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: #333;
}

@media (max-width: 769px) {
  .card {
    width: 325px;
  }
}

@media (max-width: 600px) {
  .card {
    width: 300px;
  }
}

.ds-outer {
  background-color: #1A1C40;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1920px;
}

.ds-bg-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.ds-bg-container .circle {
  border-radius: 100000px;
  position: absolute;
}

.ds-bg-container .circle.small {
  width: 766px;
  height: 766px;
  background: -webkit-gradient(linear, left top, left bottom, from(#016350), color-stop(85%, #1A1C40));
  background: -webkit-linear-gradient(#016350, #1A1C40 85%);
  background: linear-gradient(#016350, #1A1C40 85%);
  left: -258px;
  top: -300px;
}

.ds-bg-container .circle.big {
  width: 1314px;
  height: 1314px;
  background: -webkit-gradient(linear, left top, left bottom, from(#653DA8), color-stop(50%, #1A1C40));
  background: -webkit-linear-gradient(#653DA8, #1A1C40 50%);
  background: linear-gradient(#653DA8, #1A1C40 50%);
  right: -200px;
  bottom: -650px;
}

.ds-infos {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #64E887;
  padding: 0 5em;
  width: 100%;
  justify-content: space-evenly;
}

.ds-infos .contents {
  width: 456px;
}

.ds-infos .ds-title {
  font-size: 64px;
  font-weight: 700;
  line-height: 1.1;
  padding: 0 0 3rem;
}

.ds-infos .ds-description {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
}

.ds-infos .image-container img {
  width: 140px;
}

@media (max-width: 1100px) {
  .ds-bg-container {
    width: 1200px;
  }

  .ds-infos {
    flex-direction: column-reverse;
    width: auto;
    height: 550px;
    text-align: center;
  }

  .ds-infos .contents {
    width: 300px;
  }

  .ds-infos .ds-title {
    font-size: 38px;
  }

  .ds-infos .ds-description {
    font-size: 18px;
  }
}

@media (max-width: 769px) {
  /* .ds-bg-container {
    height: 700px;
    width: 800px;
  } */
}

@media (max-width: 600px) {
  /* .ds-bg-container {
    width: 600px;
  } */

  .ds-bg-container .circle.big {
    right: -600px;
    bottom: -650px;
  }
}

.uc-outer {
  background-color: #fff;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uc-container {
  width: 1920px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.uc-container .uc-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5em;
  width: 100%;
  justify-content: space-evenly;
}

.uc-container .uc-description {
  font-size: 64px;
  font-weight: 700;
  color: #111;
  padding: 0 20px;
  width: 450px;
  line-height: 1.2;
}

@media (max-width: 1100px) {
  .uc-container .uc-inner-container {
    flex-direction: column;
    text-align: center;
  }

  .uc-container .uc-description {
    font-size: 48px;
    width: 350px;
    margin-bottom: 1.25em;
  }

  .uc-container {
    width: 1200px;
    /* height: auto; */
    padding: 5em 0
  }
}

@media (max-width: 769px) {
  .uc-container {
    height: auto;
  }

  .uc-container .uc-inner-container {
    flex-direction: column;
    text-align: center;
  }

  .uc-container .uc-description {
    font-size: 38px;
  }
}

@media (max-width: 414px) {
  .uc-container .uc-description {
    font-size: 38px;
    width: 320px;
    margin-bottom: 1.25em;
  }

  .uc-container {
    width: 1200px;
    height: auto;
    padding: 5em 0
  }
}

.comment-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  min-height: 350px;
  border: 1px solid #E9E9E9;
  border-radius: 6px;
  box-shadow: 0 30px 40px 0 #E9E9E9;
  padding: 0 1rem;
}

.comment-container .comment-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 350px;
  height: 350px;
  justify-content: space-around;
}

.comment-container .comment-details .head {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comment-container .comment-details .comment-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.comment-container .comment-details .comment-score {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.comment-container .comment-details .comment-detail {
  font-size: 1rem;
  font-weight: 400;
  color: #666;
  font-style: italic;
}

.comment-container .comment-details .comment-other-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  font-weight: 300;
  color: #666;
}

.comment-container .comment-details .comment-other-info .user-nickname {
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comment-container .switch-icon {
  font-size: 24px;
  font-weight: 400;
  opacity: 0.15;
}

.comment-container .rigth-arrow, .comment-container .left-arrow {
  display: block;
}

.comment-container .rigth-arrow:hover, .comment-container .left-arrow:hover {
  cursor: pointer;
}

.comment-container .rigth-arrow:active, .comment-container .left-arrow:active {
  opacity: 0.7;
}

.comment-fade-in {
  -webkit-animation: commentFadeIn 0.5s ease;
          animation: commentFadeIn 0.5s ease;
}

.comment-fade-out {
  -webkit-animation: commentFadeOut 0.5s ease;
          animation: commentFadeOut 0.5s ease;
}

.comment-spinner {
  -webkit-animation-name: spinnerAnimation;
          animation-name: spinnerAnimation;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes commentFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes commentFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes commentFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes commentFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 1100px) {

  .comment-container {
    border: none;
    padding: 0;
    justify-content: center;
  }

  .comment-container .rigth-arrow, .comment-container .left-arrow {
    display: none;
  }
}

@media (max-width: 769px) {
}

@media (max-width: 414px) {

  .comment-container {
    width: 350px;
  }

  .comment-container .comment-details .comment-detail {
    width: 260px;
  }
}

@media (max-width: 320px) {

  .comment-container {
    width: 320px;
  }

  .comment-container .comment-details .comment-detail {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    width: 250px;
  }
}

.ff-onvisible-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ff-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 1920px;
  background-color: #000;
}

.ff-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  height: 500px;
  justify-content: space-around;
}

.real-logo-container {
  width: 96px;
  height: 96px !important;
  position: relative;
  border-radius: 24px;
}

.transitionfix {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

.real-logo-container .cutter {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 24px;
}

.real-logo-container .cutter .in-cutter {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.real-logo-container .ff-logo-eye {
  position: absolute;
  width: 62px;
  z-index: 20;
  opacity: 0.6;
  border-radius: 24px;
  background-blend-mode: color-dodge;
  top: 36px;
  left: 16px;
}

.real-logo-container .ff-logo-bg {
  width: 130px;
  height: auto;
  border-radius: 24px;
  -webkit-animation-name: fancyLogoSpin;
          animation-name: fancyLogoSpin;
  -webkit-animation-duration: 22s;
          animation-duration: 22s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.ff-new-logo {
  margin-top: 15px;
  width: 100px;
}

.ff-container .ff-text-main {
  font-size: 64px;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  margin: 0.5em auto;
  background: -webkit-linear-gradient(0, #5C0C62 8%, #F0125F 25%, #691759 43%, #691759 57%, #F0125F 70%, #5C0C62 92%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  -webkit-animation: shine 10s linear infinite;
          animation: shine 10s linear infinite;
}

.download-buttons .google {
  display: none;
}

@-webkit-keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.download-buttons .dl-button {
  margin-left: 0.75em;
  margin-right: 0.75em;
}

@-webkit-keyframes fancyLogoSpin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes fancyLogoSpin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .ff-outer {
    height: 100vh;
    width: 100%;
  }

  .ff-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    height: 500px;
    justify-content: center;
  }

  .ff-container .ff-text-main {
    font-size: 38px;
  }
}

@media (max-width: 320px) {
  .ff-container .ff-text-main {
    font-size: 32px;
  }

  .ff-container {
    justify-content: center;
  }
}

body {
  font-family: Avenir !important;
}

.main-content {
  position: relative;
  height: 250px;
  width: 100%;
}

.main-content img {
  object-fit: cover;
  width: 100%;
  height: 250px;
  z-index: -2;
}

.pt-bg-cover {
  position: absolute;
  width: 100%;
  height: 250px;
  background-color: #1C2332;
  opacity: 0.3;
  z-index: -1;
}

.content-banner {
  position: absolute;
}

.left-top-triangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 125px;
  -webkit-clip-path: polygon(0% 0, 100% 0%, 0% 100%);
          clip-path: polygon(0% 0, 100% 0%, 0% 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#1363ED), to(white));
  background: -webkit-linear-gradient(top, #1363ED, white);
  background: linear-gradient(180deg, #1363ED, white);
  opacity: 0.3;
  z-index: 5;
}

.right-triangle {
  position: absolute;
  right: 0;
  top: 0;
  height: 500px;
  width: 200px;
  -webkit-clip-path: polygon(100% 100%, 100% 0%, 0% 100%);
          clip-path: polygon(100% 100%, 100% 0%, 0% 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#1363ED), to(white));
  background: -webkit-linear-gradient(#1363ED, white);
  background: linear-gradient(#1363ED, white);
  opacity: 0.3;
  z-index: 5;
}

.banner-info {
  margin: auto auto auto 25px;
}

.main-info {
  font-size: 30px;
  font-weight: 600;
  color: white;
}

.sub-info {
  font-size: 15px;
  color: white;
  margin-top: 15px;
}

.second-content {
  background-color: #1363ED;
}

.second-text {
  color: white !important;
}

.principles-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 35px;
}

.principles-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.principles-detail {
  font-size: 13px;
  font-weight: 300px;
  opacity: 0.7;
}

.pt-section {
  background-color: #F1F4FA;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: inherit;
}

.pt-section-first {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  padding-top: 0;
}

.fake-bg {
  background-color: #1363ED;
  width: 100%;
  position: absolute;
  height: 30px;
}

.section-like-container {
  padding-left: 24px;
  padding-right: 24px;
}

.pt-container {
  background-color: #FFF;
  padding: 15px;
  border-radius: 4px;
}

.pt-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 15px;
}

.pt-title img {
  height: 16px;
  margin-right: 10px;
}

.pt-subtitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 18px;
}

.pt-text-content {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 16px;
}

.pt-text-content-last {
  position: relative;
}

.pt-text-content-last:after {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, rgba(255,255,255, 1)), color-stop(40%, rgba(255,255,255, 0)) );
  background: -webkit-linear-gradient(bottom, rgba(255,255,255, 1) 0, rgba(255,255,255, 0) 40% );
  background: linear-gradient(to top, rgba(255,255,255, 1) 0, rgba(255,255,255, 0) 40% );
}

.continue-arrow {
  width: 10px;
  height: 10px;
  -webkit-clip-path: polygon(0% 0, 100% 0%, 50% 100%);
          clip-path: polygon(0% 0, 100% 0%, 50% 100%);
  background: #1363ED;
  display: inline-block;
  margin-right: 10px;
}

.continue-reading {
  color: #1267FB;
  font-weight: 700;
  font-size: 14px;
  border: none;
  outline: none;
}

.continue-reading:hover {
  cursor: pointer;
}

@media (min-width:600px) {
  .main-content {
    height: 430px;
  }

  .main-content img {
    height: 430px;
  }

  .pt-bg-cover {
    height: 430px;
  }

  .left-top-triangle {
    width: 160px;
    height: 350px;
  }

  .right-triangle {
    height: 900px;
    width: 360px;
  }

  .banner-info {
    margin: auto auto auto 50px;
  }

  .main-info {
    font-size: 48px;
  }

  .sub-info {
    font-size: 24px;
  }

  .principles-title {
    font-size: 40px;
  }

  .principles-subtitle {
    font-size: 20px;
  }

  .principles-detail {
    font-size: 15px;
  }

  .fake-bg {
    height: 30px;
  }

  .pt-title {
    font-size: 30px;
  }

  .pt-title img {
    height: 20px;
  }

  .pt-subtitle {
    font-size: 20px;
  }

  .pt-text-content {
    font-size: 18px;
  }

  .continue-arrow {
    width: 11px;
    height: 11px;
  }

  .continue-reading {
    font-size: 16px;
  }
}

@media (min-width:801px) {
  .main-content {
    height: 650px;
  }

  .main-content img {
    height: 650px;
  }

  .pt-bg-cover {
    height: 650px;
  }

  .left-top-triangle {
    width: 225px;
    height: 475px;
  }

  .right-triangle {
    height: 1100px;
    width: 450px;
  }

  .banner-info {
    margin: auto auto auto 70px;
  }

  .main-info {
    font-size: 60px;
  }

  .sub-info {
    font-size: 30px;
  }

  .principles-title {
    font-size: 45px;
  }

  .principles-subtitle {
    font-size: 22px;
  }

  .principles-detail {
    font-size: 16px;
  }

  .pt-title {
    font-size: 32px;
  }

  .pt-title img {
    height: 21px;
  }

  .pt-subtitle {
    font-size: 20px;
  }

  .pt-text-content {
    font-size: 18px;
  }

  .continue-arrow {
    width: 11px;
    height: 11px;
  }

  .continue-reading {
    font-size: 16px;
  }
}

@media (min-width:1025px) {
  .main-content {
    height: 800px;
  }

  .main-content img {
    height: 800px;
  }

  .pt-bg-cover {
    height: 800px;
  }

  .left-top-triangle {
    width: 275px;
    height: 525px;
  }

  .right-triangle {
    height: 1200px;
    width: 550px;
  }

  .banner-info {
    margin: auto auto auto 100px;
  }

  .main-info {
    font-size: 80px;
  }

  .sub-info {
    font-size: 40px;
  }

  .principles-title {
    font-size: 45px;
  }

  .principles-subtitle {
    font-size: 22px;
  }

  .principles-detail {
    font-size: 16px;
  }

  .pt-title {
    font-size: 32px;
  }

  .pt-title img {
    height: 21px;
  }

  .pt-subtitle {
    font-size: 20px;
  }

  .pt-text-content {
    font-size: 18px;
  }

  .continue-arrow {
    width: 11px;
    height: 11px;
  }

  .continue-reading {
    font-size: 16px;
  }
}

@media (min-width:1295px) {
  .main-content {
    height: 900px;
  }

  .main-content img {
    height: 900px;
  }

  .pt-bg-cover {
    height: 900px;
  }

  .left-top-triangle {
    width: 325px;
    height: 625px;
  }

  .right-triangle {
    height: 1300px;
    width: 650px;
  }

  .banner-info {
    margin: auto auto auto 200px;
  }

  .main-info {
    font-size: 100px;
  }

  .sub-info {
    font-size: 50px;
  }

  .principles-title {
    font-size: 45px;
  }

  .principles-subtitle {
    font-size: 22px;
  }

  .principles-detail {
    font-size: 16px;
  }

  .pt-title {
    font-size: 32px;
  }

  .pt-title img {
    height: 21px;
  }

  .pt-subtitle {
    font-size: 20px;
  }

  .pt-text-content {
    font-size: 18px;
  }

  .continue-arrow {
    width: 11px;
    height: 11px;
  }

  .continue-reading {
    font-size: 18px;
  }
}

.navbar {
  position: fixed;
  width: 100%;
}

.navbar-showing {
  display: block;
}

.site-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0.75em;
}

.navbar-burger {
  outline: none;
  border: none;
  background-color: #fff;
}


/*
/ dark theme styles
 */

.navbar-dark {
   background-color: #000;
 }

.site-title-dark {
  color: white;
}

.navbar-menu-dark {
  background-color: #000;
}

.navbar-item-dark {
  color: white;
}

.nav-logo {
  border-radius: 4px;
}

.navbar-burger.dark {
  background-color: #000;
}

.navbar-burger:active {
  background-color: white;
}

.footer-section {
  background: -webkit-gradient(linear, left top, left bottom, from(#1B2231), to(#212C45));
  background: -webkit-linear-gradient(top, #1B2231, #212C45);
  background: linear-gradient(180deg, #1B2231, #212C45);
  color: white !important;
  padding-top: 10px;
  padding-bottom: 35px;
}

.footer-level-top {
  margin-bottom: 0 !important;
}

.footer-level-item-logo {
  position: relative;
  bottom: -15px;
}

.footer-level-top .footer-level-item-logo img {
  width: 66px;
}

.footer-copyright {
  font-size: 20px;
  margin-left: 1em;
}

.social-link-icon {
  margin: 0 2em 0 0;
}

.social-link-icon:hover {
  opacity: 0.7;
}

.footer-inside-link {
  font-size: 14px;
  color: white !important;
}

.footer-inside-link:hover {
  opacity: 0.7;
}

.horizontal-divider {
  display: none;
}

.footer-level-item {
  justify-content: left !important;
}

.logo-img {
  border-radius: 8px;
}

@media (min-width: 769px) {
  .horizontal-divider {
    display: block;
    border-left: solid 1px white;
    height: 1em;
    margin-right: 0.75em;
  }
}

.nf-container-cus {
  text-align: center;
  height: 500px;
  background-color: white;
  padding-top: 200px;
}

.nf-site-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0.75em;
}

.nf-page-container {
  background-color: #F1F4FA;
  height: 100vh;
}

.nf-main-info {
  font-size: 32px;
  font-weight: 600;
}

.page-container {
  position: relative;
}

.page-container .ul-banner {
  height: 100vh;
  width: 100%;
  background-image: url(//static.noseeyou.com/static/media/ul_background.eb7c4a2e.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.page-container .download-part {
  text-align: center;
  padding: 0 2em 1em;
}

.page-container .bottom-container {
  position: fixed;
  background-color: white;
  bottom: 0;
  width: 100%;
  border-radius: 25px 25px 0 0;
}

.bottom-container .center-box {
  position: absolute;
  left: 50%;
  width: 80px;
  height: 80px;
  top: -36px;
}

.ul-title {
  font-size: 24px;
  font-weight: 600;
}

.ul-text-content {
  font-size: 16px;
  font-weight: 400;
  color: #5C6880;
}

.stub-box {
  padding: 25px;
  position: relative;
}

.stub-box img {
  position: absolute;
  left: -50%;
  width: 80px;
  height: 80px;
}

.ul-main-text {
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  padding-top: 2em;
}

.ul-download-button {
  background-color: #000;
  color: white;
  font-weight: 600;
  border: 1px solid #A7A7A7;
  line-height: 1.2;
  border-radius: 8px;
  margin-top: 0.5em;
  padding: 1.6em 1.25em !important;
}

.ul-download-button:hover {
  opacity: 0.65;
  color: white !important;
}

.ul-download-button img.ul-download-icon {
  height: 2em;
  z-index: auto;
  width: 2em;
  margin-right: 1em;
}

.ul-download-button .small-text {
  font-size: 13px;
}

.ul-download-button .larger-text {
  font-size: 22px;
}

