.progress-dots-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 3rem;
  z-index: 11;
  height: 152px;
  justify-content: space-between;
}

.progress-dots-container .solid-circle.current {
  font-size: 12px;
}

.progress-dots-container .solid-circle.others {
  font-size: 8px;
  opacity: 0.3;
}

.progress-dots-container .solid-circle.colored {

}

.progress-dots-container .solid-circle.normal {

}

@media (max-width: 1440px) {
  .progress-dots-container {
    display: none;
  }
}
