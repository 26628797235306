.texts-container {
  width: 409px;
}

.texts-container .feature-point {
  font-size: 32px;
  font-weight: 500;
  color: #222;
}

.texts-container .highlight {
  font-size: 48px;
  font-weight: 700;
  color: #FF5100;
  line-height: 1.1;
  padding: 0.5em 0;
}

.texts-container .highlight.purple {
  color: #B63CE3;
}

.texts-container .highlight.yellow {
  color: #FF9F00;
}

.texts-container .highlight.cyan {
  color: #2A928A;
}

.texts-container .description .items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.texts-container .description .items .plain-text {
  font-size: 18px;
  font-weight: 700;
}

.texts-container .description .items .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.25em;
}

.texts-container .description .items .item .icon {
  width: 34px;
  height: auto;
  margin-right: 2.25em;
}

.texts-container .description .items .item .comment {
  font-size: 18px;
}

@media (max-width: 1200px) {
  .texts-container {
    width: 320px;
  }
}

@media (max-width: 320px) {
  .texts-container {
    width: 280px;
  }
}
