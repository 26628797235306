.navbar {
  position: fixed;
  width: 100%;
}

.navbar-showing {
  display: block;
}

.site-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0.75em;
}

.navbar-burger {
  outline: none;
  border: none;
  background-color: #fff;
}


/*
/ dark theme styles
 */

.navbar-dark {
   background-color: #000;
 }

.site-title-dark {
  color: white;
}

.navbar-menu-dark {
  background-color: #000;
}

.navbar-item-dark {
  color: white;
}

.nav-logo {
  border-radius: 4px;
}

.navbar-burger.dark {
  background-color: #000;
}

.navbar-burger:active {
  background-color: white;
}
