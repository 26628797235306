.content-outer {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container {
  display: flex;
  width: 1920px;
  height: 100vh;
  padding: 5em 0 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.content-container.direction-normal {
  flex-direction: row;
}

.content-container.direction-reverse {
  flex-direction: row-reverse;
}

.content-container .text-part, .content-container .image-part {
  width: 50%;
}

.content-container .text-part {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10em;
}



.content-container .text-part.reversed {
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .content-container .text-part {
    padding: 0 2em;
  }

  .content-container .image-part {
    padding: 2em;
  }
}

@media (max-width: 769px) {
  .content-container {
    height: auto;
  }

  .content-container.direction-normal {
    flex-direction: column;
  }

  .content-container.direction-reverse {
    flex-direction: column;
  }

  .content-container .text-part {
    justify-content: center;
  }

  .content-container {
    height: auto;
    padding: 5em 0 0;
  }

  .content-container .text-part.reversed {
    justify-content: center;
  }
}
